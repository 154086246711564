.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #c1c1c1;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 1rem;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: rgba(247, 128, 7, 0.1);
  border-color: #F78007 #F78007 #fff;
  color: black;
  border-radius: 5px 5px 0 0;
  position: relative;
  z-index: 9;
  
}
.fade-in {
  animation-name: fadeIn; 
  animation-duration: 0.4s; 
  animation-timing-function: linear; 
}
@keyframes fadeIn { from { opacity: 0; } to { opacity: 1; } }
.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab {
  outline: none;
}
/* 
.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 1px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #fff;
}  */

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
