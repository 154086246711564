.pointer{
	cursor:pointer;
}
.bg-f1{
    background-color: #f1f1f1 !important;
}
.bg-f5{
    background-color: #f5f5f5 !important;
}
.bg-ff{
    background-color: #ffffff !important;
}
.pd-20-16{
    padding: 20px 16px;
}
.pd-t-8{
    padding-top: 8px;
}
.pd-t-15{
    padding-top: 15px;
}
.input-border-bt{
    background: transparent;
    border:none;
    border-bottom: 1px solid #fff;
    color: #fff;
    display: block;
    width: 100%;
    height: calc(1.9695rem + 2px);
    padding: 0.375rem 0.2rem;
    font-size: 0.813rem;
    line-height: 1.5;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-border-bt:focus{
    color: #fff;
    background-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 128, 7, 0.25);
}
.input-border-bt::placeholder {
    color: #e9e9e9;
    opacity: 1; }
.yearly-setting-card{
    border: 0 !important;
    overflow: hidden !important;
    margin-bottom: 1.5rem !important;
    background-color: transparent;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    margin-top:14px;
}
.yearly-setting-card .cardBodySet{
    height: 400px;
    overflow-y: auto;
    padding: 0 !important;
    flex: 1 1 auto;
}
.cardBodySet .listGroup{
    display: flex;
    position:relative;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    cursor:pointer;
    background-color:rgba(255,255,255,0);
    border-bottom:1px solid rgba(0,0,0,0.04);
}
.listGroupEnd{
    display: flex;
    position:relative;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    cursor:pointer;
    background-color:rgba(255,255,255,0);
}
.listGroupItem{
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
    color: #fff;
    position: relative;
    display: block;
    padding: 2px 16px;
    margin-bottom: -1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.listGroupItem .timelineCircleWrap{
    height: 100%;
    width: 100%;
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
}
.listGroupItem .textTitle{
    font-size: 16px;
    color:#222;
    margin-left:16px;
}
.timelineCircleWrap .timelineCircleGrey{
    height: 14px;
    width: 14px;
    border-radius: 10px;
    border: 2px solid #ffffff;
    display: flex;
    justify-content:center;
    align-items:center;
    z-index: 1;
    border-color: #999 !important;
}
.timelineCircleGrey .bgWarningGrey{
    height: 6px;
    width: 6px;
    border-radius: 5px;
    background-color: #999 !important;
}
.timelineCircleWrap .timelineCircleActive{
    height: 14px;
    width: 14px;
    border-radius: 10px;
    border: 2px solid #ffffff;
    display: flex;
    justify-content:center;
    align-items:center;
    z-index: 1;
    border-color: #F78007 !important;
}
.timelineCircleActive .bgWarningActive{
    height: 6px;
    width: 6px;
    border-radius: 5px;
    background-color: #F78007 !important;
}
.timelineCircleLineVertical{
    position: absolute;
    width: 1px;
    display: block;
    left: 8px;
    z-index: 0;
    background-color: rgba(0,0,0,0.04);
    height: 100%;
    
}
.menu-burger-box{
	display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.menu-burger-box div{
	width: 24px;
    height: 1px;
    background: #47404f;
    margin: 3px 0;
}
.delete-btn-card{
	position: absolute;
	right: 9px;
    top: 13px;
	cursor: pointer;
}
.add-btn-card{
	position: absolute;
	right: 59px;
    top: 13px;
	cursor: pointer;
}
.edit-btn-card{
	position: absolute;
	right: 34px;
    top: 13px;
	cursor: pointer;
}
.authen-btn-card{
	position: absolute;
	right: 59px;
    top: 13px;
	cursor: pointer;
}
.padding-1-9-rem{
	padding: 1.9rem !important;
}
.conner-desc-btn-card{
	position: absolute;
	left: 16px;
    top: 10px;
}
.circle-avatar img{
	margin: 4px 0px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.table-header-bottom-line{
	border-bottom: 1px solid #dee2e6;
}
.header-modal-two-line{
	display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.input-row-with-desc-start{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.input-row-with-desc-start span{
    width: 20px;
    font-size: 14px;
    margin-top: 1px;
}
.fabSet{
    width: 36px;
    height: 36px;
    background: linear-gradient(-135deg,#1de9b6,#1dc4e9);
    color: rgba(0, 0, 0, 0.87);
    width: 56px;
    height: 56px;
    padding: 0;
    font-size: 0.875rem;
    min-width: 0;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    box-sizing: border-box;
    min-height: 36px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 50%;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    background-color: #e0e0e0;
}
.row-flex-around{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.row-flex-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.row-flex-center-align{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.icon-hover-bold{
    margin-top: 6px;
    font-weight: unset;
}
.icon-hover-bold:hover{
    border-radius: 50%;
    box-shadow: 0 14px 26px -12px rgba(247,128,7, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(247,128,7, 0.2);
}
.top-right-btn-position{
    position: absolute;
    right: 14px;
    z-index: 10;
}
.right-btn-position{
    float: right;
    margin-top: 10px;
}
.float-right-position{
    float: right;
}
.icon-view{

}
.icon-refresh-profile{
    font-weight: bold;
    color: #666;
}
.avatar-percent{
    width: 120px;
}
.avatar-percent-present{
    width: 150px;
}
.text-sub-light{
    color: #7d7d7d;
}
.profile-picture-border-0{
    border-radius: 0% !important;
    border: 0px !important;
}
.drag-border-custom{
    border-bottom:dashed 1px #F78007 !important;
}
.table-row-moveable{
    cursor: move;
}
.flex-row-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.flex-row-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.flex-row-start{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}
.profile-box-custom-upload-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.btn-icon-custom-upload-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.toolbar-custom-right{
    padding-right: 160px;
}
.finish-hide{
    display: none;
}
.finish-show{
    display: block;
}
.tbl-column-width-20{
    width: 20%;
}
.tbl-column-width-8-center{
    width: 8%;
    text-align: center;
}
.tbl-column-width-12-center{
    width: 12%;
    text-align: center;
}
.tbl-column-width-30px{
    width: 30px;
}
.tbl-column-width-60px{
    width: 60px;
}
.tbl-column-center{
    text-align: center;
}
.set-grey-bg-row-pt-16{
    background-color: #EFF2F5;
    margin-bottom: 22px;
    padding: 16px 0px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
}
.set-grey-bg-row{
    background-color: #EFF2F5;
    margin-bottom: 22px;
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
}
.set-grey-bg-row-no-padding-bt{
    background-color: #EFF2F5;
    margin-bottom: 22px;
    padding-top: 10px;
    padding-right: 0px;
    padding-left: 0px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
}
.set-grey-bg-column{
    background-color: #EFF2F5;
    margin-bottom: 22px;
    padding: 20px 0px;
    flex-wrap: wrap;
    border-radius: 8px;
}

/* .set-grey-bg-row{
    background-color: #f5f5f5;
    margin-bottom: 22px;
    padding: 20px 8px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
}
.set-grey-bg-row-no-padding-bt{
    background-color: #f5f5f5;
    margin-bottom: 22px;
    padding-top: 20px;
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
}
.set-grey-bg-column{
    background-color: #f5f5f5;
    margin-bottom: 22px;
    padding: 20px 20px;
    flex-wrap: wrap;
    border-radius: 8px;
} */

.empty-add-box-pd{
    padding-top: 33px;
    padding-bottom: 20px;
}
.flex-set{
    display: flex;
}
.th-with-icon i{
    position: relative;
    top: 2px;
}
.th-with-icon span{
    position: relative;
    top: -2px;
}
.mt-25-px{
    margin-top: 25px;
}
.mt-20-px{
    margin-top: 20px;
}
.mt-10-px{
    margin-top: 10px;
}
.icon-delete-out-feild{
    position: absolute;
    right:-24px;
}
.icon-close-card{
    position: absolute;
    right:20px;
    top:6px;
    z-index: 990;
}
.icon-edit-card{
    position: absolute;
    right:40px;
    top:6px;
    z-index: 990;
}
.bg-grey{
    background-color: #f5f5f5;
    border-radius: 8px;
    position: relative;
}
.mb-nega-2{
    margin-bottom: -2px;
}
.mb-nega-5{
    margin-bottom: -5px;
}
.mt-nega-5{
    margin-top: -5px;
}
.ml-nega-16{
    margin-left: -14px;
}
.mr-nega-14{
    margin-right: -14px;
}
.mr-14{
    margin-right: 14px;
}
.custom-card-v1{
    box-shadow: none !important;
    border: 1px solid #eaeaea;
    border-radius: 6px !important;
    border-bottom: 1px solid #eaeaea !important;
    cursor: pointer;
}
.img-circle-box-32{
    margin: 4px 0px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.img-circle-box{
    margin: 0px 0px;
    border-radius: 50%;
    height: 100%;
}
.z-999{
    position: relative;
    z-index: 11;
}
.serach-student-box{
    display: flex;
    align-items: center;
    justify-content: left;
    background: #f8f9fa;
    border: 1px solid #eee;
    border-radius: 20px;
    position: relative;
    width: 50%;
    height: 40px;
}
.serach-student-box input{
    background: transparent;
    border: 0;
    color: #212121;
    font-size: .8rem;
    line-height: 2;
    height: 100%;
    outline: initial !important;
    padding: .5rem 1rem;
    width: calc(100% - 32px);
}
.student-top-bar-table{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.student-top-bar-table-row{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.tab-height-half{
    padding: 0.5rem !important;
}
.border-bottom-none{
    border-bottom:unset !important;
}
.display-none{
    display: none;
}
.display-show{
    display: block;
}
.row-user-link-box{
    background-color: #f8f9fa;
    padding: 16px 0px;
}
.flex-row-align-center{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}
.flex-column-justify-center{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.flex-column-row-right{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}
.flex-column-left{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}
.flex-column-column-right{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
}
.pd-poi8rem{
    padding: 0.8rem !important;
}
.vertical-middle{
    vertical-align: middle !important;
}
.border-bottom-unset{
    border-bottom: unset !important;
}
.font-weight{
    font-weight: bold !important;
}
.font-weight-unset{
    font-weight: unset !important;
}
.icon-subject{
    position: relative;
    top: 3px;
}
.border-top-radius{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.margin-horizontal-unset{
    margin-right: unset !important;
    margin-left: unset !important;
}
.fix-bottom-box{
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  background-color: #F78007;
}
.stepper-custom{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width:100%;
    height:100px;
    position: relative;
    z-index: 0;
}
.line-back{
    width:100%;
    height:5px;
    background-color: #f5f5f5;
    position:absolute;
    left:0px;
    top: 35px;
}
.each-box{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width:20%;
    height:100%;
    position: relative;
    cursor: pointer;
}
.each-box-fake-two{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width:60%;
    height:100%;
    position: relative;
}
.each-box-fake-tree{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width:60%;
    height:100%;
    position: relative;
}
.section-text{
    height: 70px;
    width:100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.section-line-fake{
    height: 30px;
    width:100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.section-line-fake::after{
    content: "";
    width:100%;
    height:1px;
    background-color: #EFF2F5;
    position:absolute;
    left: 0px;
    top: 0px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}
.section-line{
    height: 30px;
    width:100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.section-line::after{
    content: "";
    width:100%;
    height:1px;
    background-color: #f5f5f5;
    position:absolute;
    left: 0px;
    top: 0px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}
.section-line::before{
    content: "";
    position:absolute;
    border-radius: 50%;
    color: #428bca;
    background-color: #EFF2F5;
    border: none;
    width: 30px;
    height: 30px;
    bottom: 0px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.section-line-only-after{
    height: 30px;
    width:100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.section-line-only-after::after{
    content: "";
    width:50%;
    height:1px;
    background-color: #EFF2F5;
    position:absolute;
    right: 0px;
    top: 0px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}
.section-line-only-before{
    height: 30px;
    width:100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.section-line-only-before::after{
    content: "";
    width:50%;
    height:1px;
    background-color: #EFF2F5;
    position:absolute;
    left: 0px;
    top: 0px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.section-without-line{
    height: 30px;
    width:100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.checked-icon{
    border-radius: 50%;
    width: 19px;
    height: 19px;
    position: relative;
    z-index: 10;
    margin: auto;
}
.checked-icon-lg{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 10;
    margin: auto;
}
.checked-icon-front-title{
    margin-top:-3px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 10;
}
.inner-circle-normal{
    background-color: #525252;
    border: none;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: relative;
    z-index: 999;
}
.inner-circle-gray{
    background-color: #a9a9a9;
    border: none;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: relative;
    z-index: 999;
}
.inner-circle-maked{
    background-color: #4eb553 ;
    border: none;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: relative;
    z-index: 999;
}
.inner-circle-active{
    background-color: #ffc107 ;
    border: none;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: relative;
    z-index: 999;
    animation-name: color_change;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
@-webkit-keyframes color_change {
    from { background-color: #ffc107 ; }
    to { background-color: #f5f5f5; }
}
@-moz-keyframes color_change {
    from { background-color: #ffc107 ; }
    to { background-color: #f5f5f5; }
}
@-ms-keyframes color_change {
    from { background-color: #ffc107 ; }
    to { background-color: #f5f5f5; }
}
@-o-keyframes color_change {
    from { background-color: #ffc107 ; }
    to { background-color: #f5f5f5; }
}
@keyframes color_change {
    from { background-color: #ffc107 ; }
    to { background-color: #f5f5f5; }
}
.text-step-en{
    font-size: 14px;
    color:#444;
}
.text-step-desc{
    font-size: 12px;
    color:#888;
}
.row-flex-between{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.row-flex-start{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}
.set-pest{
    padding: 0px !important;
    width: 30px;
    height: 30px;
    border-radius:50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.set-pest-24{
    padding: 0px !important;
    width: 24px;
    height: 24px;
    border-radius:50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:16px;
}
.text-plus-custom{
    font-size:18px;
    color: #fff;
}
.text-plus-custom-16{
    font-size:16px;
    color: #fff;
}
.input-with-delete-row{
    position: relative;
}
.delete-input{
    position: absolute;
    right: -10px;
    bottom: 8px;
}
.loading-btn-set{
    position: relative;
    top: 6px;
    right: 20px;
}
.toast-custom{
    position: fixed;
    right: 12px;
    z-index: 10;
}
.toast-header-custom{
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.alert-green{
    background-color: rgba(76, 175, 80, 1) !important;
}
.icon-yes-custom{
    position: relative;
    top: 3px;
}
.flex{
    display: flex;
}
.bg-green{
    background-color: #4eb553;
}
.scale-text-left{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-100w{
    width: 100%;
    height: 100%;
    max-width: unset !important;
}
.hyper-modal-wrapper-custom{
    z-index: 999;
}
.hyper-modal-content-custom{
    width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
    padding: 20px;
    background-color: #EFF2F5 !important;
}
.pd-20{
    padding: 20px;
}
.bg-white-line-step{
    background-color: unset !important;
}
.bg-white-line-step::after{
    background-color: #dee2e6 !important;
}
.bg-white{
    background-color: #ffffff !important;
}
.border-left-right-th{
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: unset !important;
}
.border-right-th{
    border-right: 1px solid #dee2e6;
}
.border-right-f5{
    border-right: 1px solid #f5f5f5 !important;
}
.border-left-f5{
    border-left: 1px solid #f5f5f5 !important;
}
.border-bottom-none{
    border-bottom: unset;
}
.hyper-modal-close-icon-custom{
    width: 28px !important;
    height: 28px !important;
    right: 35px !important;
}
.text-weight-none{
    font-weight: 100 !important;
}
.text-preview-title {
    font-size: 20px;
    font-weight: bold;
    color: #666;
}
.text-preview-title-small {
    font-size: 16px;
    font-weight: bold;
    color: #666;
    position: relative;
}
.text-preview-title-medium {
    font-size: 20px;
    font-weight: bold;
    color: #666;
    position: relative;
}
/*.text-preview-title-small::after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 50px;
    height: 3px;
    border-radius: 4px;
    background-color: #F78007;
}*/
.bg-white-form{
    background-color: #ffffff !important;
}
.icon-delete-bg-grey{
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 999;
}
.fill-empty-text{
    font-size: 14px;
    color: red;
}
.text-pink{
    color: #CB3066;
}
.text-green{
    color: #20c997;
}
.icon-steper{
    font-size: 26px;
    margin-bottom: 6px;
    color: #888;
}
.btn-circle-primary{
    cursor: pointer;
    background: #F78007;
    padding: 0px !important;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-circle-primary:hover{
    background: #F78007;
    box-shadow: 0 8px 25px -8px #F78007;
    border-color: #F78007;
}
.btn-circle-add{
    cursor: pointer;
    background: #5cc25f;
    padding: 0px !important;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-circle-add:hover{
    background: #4eb553;
    box-shadow: 0 8px 25px -8px #4eb553;
    border-color: #4eb553;
}
.btn-circle-edit{
    cursor: pointer;
    background: #5284bd;
    padding: 0px !important;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-circle-edit:hover{
    background: #3d6ba0;
    box-shadow: 0 8px 25px -8px #3d6ba0;
    border-color: #3d6ba0;
}
.btn-circle-delete{
    cursor: pointer;
    background: #fb8e7e;
    padding: 0px !important;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-circle-delete:hover{
    background: #fa6e4f;
    box-shadow: 0 8px 25px -8px #fa6e4f;
    border-color: #fa6e4f;
}
.btn-edit-step{
    position: absolute;
    right: 0;
    left: 40px;
    top: -14px;
    margin-left: auto;
    margin-right: auto;
}
.btn-circle-delete-small{
    cursor: pointer;
    background: #fb8e7e;
    padding: 0px !important;
    width: 18px;
    height: 18px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-circle-delete-small:hover{
    background: #fb8e7e;
    box-shadow: 0 8px 25px -8px #fb8e7e;
    border-color: #fb8e7e;
}
.btn-circle-delete-small .text-plus-custom{
    font-size:10px;
    color: #fff;
}
.btn-circle-edit-small{
    cursor: pointer;
    background: #5284bd;
    padding: 0px !important;
    width: 18px;
    height: 18px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-circle-edit-small:hover{
    background: #5284bd;
    box-shadow: 0 8px 25px -8px #5284bd;
    border-color: #5284bd;
}
.btn-circle-edit-small .text-plus-custom{
    font-size: 10px;
    margin-bottom: -2px;
    color: #fff;
}
.icon-hover{
    color:#47404f !important;
}
.icon-hover:hover{
    color:#F78007 !important;
}
.position-relative{
    position: relative;
}
.width-fifty-per{
    width: 50%;
}
.step-section-body-bg-white{
    padding: 20px 10px;
    border-radius: 16px;
}
.mr-dot-five{
    padding-right:0.5rem
}
.margin-unset{
    margin: unset !important;
}
.pt-1-rem{
    padding-top: 1rem !important;
}
.pt-2-rem{
    padding-top: 2rem !important;
}
.mb-nega-20{
    margin-bottom: -20px;
}
.text-999 {
    color: #999999; 
}
.img-responsive{
    width: 100%;
    height: auto;
}
.margin-auto{
    margin: auto;
}
.container-frame{
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.profile-upload{
    position: absolute;
    bottom: 10px;
    right:4px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #e4e6eb;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.profile-upload:hover{
    background-color: #d8dbe2;
}
.profile-upload-img{
    height: 22px !important;
}
.icon-change-schedule{
    position: absolute;
    right: 32px;
    top: 6px;
}
.icon-remove-schedule{
    position: absolute;
    right: 6px;
    top: 6px;
}
.line-height-poi-5{
    line-height:0.5;
}
.box-sa-name{
    border-radius: 10px !important;
    border-color: #70657b;
    color: #fff;
    background-color: #c1c1c1;
    font-size: 16px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border: 1px solid transparent;
}
.login-title{
    font-weight: 900;
    font-size: 56px;
    color: #fff !important;
    text-align: center !important;
}
.login-sub-title{
    font-size: 28px;
    line-height: 0.5;
    margin-bottom: 1rem !important;
    color: #fff !important;
    text-align: center !important;
}
.auth-content{
    width: 100%;
}
.login-form{
    padding-top: 1.5rem;
}
@media only screen and (max-width: 680px){
    .login-title{
        font-size: 26px;
    }
    .login-sub-title{
        font-size: 14px;
        line-height: 1;
    }
    .box-sa-name{
        font-size: 14px;
    }
    .login-form{
        padding-top: 0.5rem;
        padding-left: 30px;
        padding-right: 30px;
    }
}
.box-line-divider{
    border-top: 1px solid #eee;
}

.logo-box{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
}
.font-logo-bar{
    font-size: 16px;
    color:#333333;
    margin-left: -10px;;
    margin-bottom: -2px;
}
@media only screen and (max-width: 680px){
    .font-logo-bar{
        display: none;
    }
}
.bg-text-with-blur {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
    color: white;
    border: 3px solid #f1f1f1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    line-height: 1.4;
}
.bg-image-blur{
    filter: blur(3px);
    -webkit-filter: blur(3px);
    height: 400px;
}
.first {
    background: url("../images/education-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.second {
    background: url("../images/education-2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.third {
    background: url("../images/education-3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.fourth {
    background: url("../images/project.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.mb-10-px{
    margin-bottom: 10px !important;
}
.text-title-document{
    font-size: 36px;
}
.text-name-document{
    font-size: 20px;
}
.mb-3-res-991{
    margin-bottom: 0rem !important;
}
.mt-2-rem{
    margin-top: 2rem !important;
}
.shadow-primary{
    box-shadow: 0 4px 20px 1px rgba(78, 181, 83, 0.2), 0 1px 4px rgba(78, 181, 83, 0.1) !important;
}
.shadow-danger{
    box-shadow: 0 4px 20px 1px rgba(251, 142, 126, 0.2), 0 1px 4px rgba(251, 142, 126, 0.1) !important;
}
.hover-primary{
    cursor: pointer;
    color:#000000 !important;
}
.hover-primary:hover{
    cursor: pointer;
    color:#F78007 !important;
}
.title-orange{
    color: #fff;
    background-color: #F78007;
    border-radius: 0.25rem;
    display: block;
    padding: 0.5rem 1rem;
    cursor: pointer;
}
.title-primary{
    color: #fff;
    background-color: #F78007;
    border-radius: 16px;
    display: block;
    padding: 0.5rem 1rem;
    cursor: pointer;
}
.plan-not-approve-btn{
    background-color: #fb8e7e;
    width: 90px;
    height: 32px;
    border-radius: 8px;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.plan-approved-btn{
    background-color: #5cc25f;
    width: 90px;
    height: 32px;
    border-radius: 8px;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.text-plan-approve{
    color: #f1f1f1;
    font-size: 14px;
}
.btn-school-filter{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    background: #e1e1e1;
    border: 1px solid #eee;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    height: 40px;
    position: absolute;
    right:10px;
    top: 10px;
}
.icon-circle-action{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #F78007;
    display: flex;
    align-items: center; 
    justify-content: center;
}
@media only screen and (max-width: 991px) {
    .mb-3-res-991{
        margin-bottom: 1rem !important;
    }
}
@media only screen and (max-width: 720px) {
    .bg-image-blur{
        filter: blur(3px);
        -webkit-filter: blur(3px);
        height: 250px;
    }
    .text-title-document {
        font-size: 20px;
    }
    .text-name-document{
        font-size: 16px;
    }
    .plan-not-approve-btn{
        background-color: #fb8e7e;
        width: 100px;
        height: 32px;
        border-radius: 8px;
        position: relative;
        right: 10px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .text-plan-approve{
        color: #f1f1f1;
        font-size: 14px;
    }
}
#imagePreview {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

/* #imagePreview:hover {opacity: 0.7;} */

/* The Modal (background) */
.modal-image-preview {
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-image-preview-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 800px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-image-preview-content, #caption {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}

/* The Close Button */
.closeImagePreview {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.closeImagePreview:hover,
.closeImagePreview:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
    .modal-image-preview-content {
      width: 100%;
    }
}
.sidebar-icon-pull{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 0px;
    bottom: 0px;
    right: -14px;
    z-index: 99px;
    position: absolute;
    width: 14px;
    height: 60px;
    background-color: #525252;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
}
.sidebar-icon-pull span{
    color: #d1d1d1;
}
.reset-password-container{
    background-color: #f0f2f5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding: 30px;
}
.forgot-password-img{
    width: 100%;
    max-width: 300px;
    height: auto;
}