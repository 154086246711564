.ct-timeline .ct-timeline-item .ct-card {
	max-height: 300px;
}

.ct-timeline .ct-timeline-item {
    display: flex;
    position: relative;
}

.ct-timeline .ct-timeline-item::before {
    background: #dadee4;
    content: "";
    height: 100%;
    left: 12px;
    position: absolute;
    top: 20px;
    width: 2px;
	z-index: 0;
}
.ct-timeline .ct-timeline-item .ct-timeline-icon {
	z-index: 0;
}
.ct-timeline .ct-timeline-item .ct-timeline-icon .ct-badge {
	margin-top: 0px;
    width: 26px;
    height: 26px;
}

.ct-timeline .ct-timeline-item .ct-timeline-content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 0 0 1rem;
}
.check-icon {
    width: 12px;
    height: 12px; 
}

.uk-container {
    display: flow-root;
    /* 1 */
    box-sizing: content-box;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    /* padding-left: 15px;
    padding-right: 15px; */
}
/* Phone landscape and bigger */
@media (min-width: 640px) {
    .uk-container {
        /* padding-left: 30px;
        padding-right: 30px; */
    }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
    .uk-container {
        /* padding-left: 40px;
        padding-right: 40px; */
    }
}
/*
* Remove margin from the last-child
*/
.uk-container > :last-child {
    margin-bottom: 0;
}
/*
* Remove padding from nested containers
*/
.uk-container .uk-container {
    padding-left: 0;
    padding-right: 0;
}
.uk-padding {
    padding-top: 30px;
    padding-bottom: 30px;
}
/* Desktop and bigger */
@media (min-width: 1200px) {
    .uk-padding {
    padding: 40px;
    }
}
.ct-badge {
    box-sizing: border-box;
    min-width: 18px;
    height: 18px;
    padding: 0 5px;
    border-radius: 500px;
    vertical-align: middle;
    /* 1 */
    background: #4eb553;
    color: #fff !important;
    font-size: 11px;
    /* 2 */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
}
.ct-bg-green{
    background: #4eb553;
}
.ct-bg-yellow{
    background: #FFC107;
    animation-name: color_change;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.ct-bg-grey{
    background-color: #a9a9a9;
}
@-webkit-keyframes color_change {
    from { background-color: #ffc107 ; }
    to { background-color: #f5f5f5; }
}
@-moz-keyframes color_change {
    from { background-color: #ffc107 ; }
    to { background-color: #f5f5f5; }
}
@-ms-keyframes color_change {
    from { background-color: #ffc107 ; }
    to { background-color: #f5f5f5; }
}
@-o-keyframes color_change {
    from { background-color: #ffc107 ; }
    to { background-color: #f5f5f5; }
}
@keyframes color_change {
    from { background-color: #ffc107 ; }
    to { background-color: #f5f5f5; }
}
/*
* Required for `a`
*/
.ct-badge:hover {
    text-decoration: none;
}
/* ========================================================================
   Component: Card
 ========================================================================== */
.uk-card {
    position: relative;
    box-sizing: border-box;
    transition: box-shadow 0.1s ease-in-out;
}
/* Sections
========================================================================== */
.uk-card-body {
    display: flow-root;
    padding: 10px 20px;
}
.uk-card-header {
    display: flow-root;
    padding: 15px 20px;
}
.uk-card-footer {
    display: flow-root;
    padding: 15px 20px;
}
/* Desktop and bigger */
@media (min-width: 1200px) {
    .uk-card-body {
    padding: 20px 20px;
    }
    .uk-card-header {
    padding: 20px 20px;
    }
    .uk-card-footer {
    padding: 20px 20px;
    }
}
/*
* Remove margin from the last-child
*/
.uk-card-body > :last-child,
.uk-card-header > :last-child,
.uk-card-footer > :last-child {
    margin-bottom: 0;
}
/* Media
========================================================================== */
/*
* Reserved alignment modifier to style the media element, e.g. with `border-radius`
* Implemented by the theme
*/
/* Title
========================================================================== */
.uk-card-title {
    font-size: 16px;
    color: #444;
    line-height: 1;
}
.uk-card-text-sub {
    font-size: 16px;
    color: #888;
}
/* Hover modifier
 ========================================================================== */
.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
    background-color: #fff;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}
/* Style modifiers
========================================================================== */
/*
* Default
* Note: Header and Footer are only implemented for the default style
*/
.uk-card-default {
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}
.uk-card-default .uk-card-title {
    color: #333;
}
.uk-card-default.uk-card-hover:hover {
    background-color: #fff;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}
.uk-card-default .uk-card-header {
    border-bottom: 1px solid #e5e5e5;
}
.uk-card-default .uk-card-footer {
    border-top: 1px solid #e5e5e5;
}
.uk-margin-medium-bottom {
    margin-bottom: 40px !important;
}
.uk-overflow-auto {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 10px;
}
.uk-overflow-auto > :last-child {
    margin-bottom: 0;
}
.uk-grid {
    display: flex;
    /* 1 */
    flex-wrap: wrap;
    /* 2 */
    margin: 0;
    padding: 0;
    list-style: none;
}
/*
* Grid cell
* Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
* Reset margin for e.g. paragraphs
*/
.uk-grid > * {
    margin: 0;
}
/*
* Remove margin from the last-child
*/
.uk-grid > * > :last-child {
    margin-bottom: 0;
}
/* Gutter
========================================================================== */
/*
* Default
*/
/* Horizontal */
.uk-grid {
    margin-left: -30px;
}
.uk-grid > * {
    padding-left: 30px;
}
/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
    margin-top: 30px;
}
/* Desktop and bigger */
@media (min-width: 1200px) {
    /* Horizontal */
    .uk-grid {
    margin-left: -40px;
    }
    .uk-grid > * {
    padding-left: 40px;
    }
    /* Vertical */
    .uk-grid + .uk-grid,
    .uk-grid > .uk-grid-margin,
    * + .uk-grid-margin {
    margin-top: 40px;
    }
}
/* Horizontal */
.uk-grid-small,
.uk-grid-column-small {
  margin-left: -15px;
}
.uk-grid-small > *,
.uk-grid-column-small > * {
  padding-left: 15px;
}
/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid + .uk-grid-row-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 15px;
}
.uk-flex-middle {
    align-items: center;
}
.uk-light .uk-label,
.uk-section-primary:not(.uk-preserve-color) .uk-label,
.uk-section-secondary:not(.uk-preserve-color) .uk-label,
.uk-tile-primary:not(.uk-preserve-color) .uk-label,
.uk-tile-secondary:not(.uk-preserve-color) .uk-label,
.uk-card-primary.uk-card-body .uk-label,
.uk-card-primary > :not([class*='uk-card-media']) .uk-label,
.uk-card-secondary.uk-card-body .uk-label,
.uk-card-secondary > :not([class*='uk-card-media']) .uk-label,
.uk-overlay-primary .uk-label,
.uk-offcanvas-bar .uk-label {
    background-color: #fff;
    color: #666;
}
.uk-label {
    display: inline-block;
    padding: 0 10px;
    background: #4eb553;
    line-height: 1.5;
    font-size: 0.875rem;
    color: #fff;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 2px;
    text-transform: uppercase;
}
/* Color modifiers
========================================================================== */
/*
* Success
*/
.uk-label-success {
    background-color: #32d296;
    color: #fff;
}
/*
* Warning
*/
.uk-label-warning {
    background-color: #faa05a;
    color: #fff;
}
/*
* Danger
*/
.uk-label-danger {
    background-color: #f0506e;
    color: #fff;
}
.uk-margin-auto-left {
    margin-left: auto !important;
}
.uk-text-success {
    color: #32d296 !important;
}