@import url('https://fonts.googleapis.com/css2?family=Sarabun&display=swap');
.news-box{
    overflow: hidden;
    border-radius: 16px;
    width: 100%;
    min-height:400px;
    position: relative;
}
.bg-green{
    background-color: rgba(142, 238, 185, 0.6);
}
.bg-blue{
    background-color: #C0E5FE;
}
.bg-red{
    background-color: rgba(255, 177, 175, 0.5);
}
.bg-orange{
    background-color: #FFEACB;
}
.bg-violet{
    background-color: #D5D4F5;
}
.bg-grey-news{
    background-color: #fff;
}
.img-cover-news{
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.img-4-right{
    width: 100%;
    height: 400px;
    object-fit: cover;
}
.img-sm-show{
    display: none;
}
.img-sm-hide{
    display: flex;
}

.img-12-top{
    width: 100%;
    height: 220px;
    object-fit: cover;
}
.news-8-left{
    padding: 40px 16px 0px 64px;
}
.news-12-bottom{
    padding: 12px 20px 0px 20px;
    
}
.news-8-left .title{
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    line-height: 1.2;
}
.news-8-left .desc{
    font-size: 14px;
    color: #444444;
    line-height: 1.4;
}
.news-12-bottom .title{
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    line-height: 1.2;
}
.news-12-bottom .desc{
    font-size: 14px;
    color: #444444;
    line-height: 1.4;
}
.news-12-bottom .btn-right-poi{
    position: absolute;
    right: 40px;
    bottom: -40px;
}
.news-main .btn-right-poi{
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.btn-read-lg{
    cursor: pointer;
    width:110px;
    height:34px;
    margin-top:20px;
    border-radius: 16px;
    background-color: #EFF2F5;
}
.btn-read-lg span{
    font-size: 14px;
    color: #444444;
}
.btn-read-md{
    cursor: pointer;
    width:110px;
    height:34px;
    border-radius: 16px;
    background-color: #EFF2F5;
}
.btn-read-md span{
    font-size: 14px;
    color: #444444;
}
@media only screen and (max-width: 991px) {
    .img-sm-show{
        display: flex;
    }
    .img-sm-hide{
        display: none;
    }
    .img-4-right{
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
    .news-8-left{
        padding: 16px 16px 0px 20px;
    }
    .news-8-left .title{
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        line-height: 1.2;

    }
    .news-8-left .desc{
        font-size: 13px;
        color: #444444;
        line-height: 1.4;
    }
    .news-12-bottom .title{
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        line-height: 1.2;

    }
    .news-12-bottom .desc{
        font-size: 13px;
        color: #444444;
        line-height: 1.4;
    }
    .btn-read-lg{
        cursor: pointer;
        width:100px;
        height:32px;
        margin-top:20px;
        border-radius: 16px;
        background-color: #EFF2F5;
    }
    .btn-read-lg span{
        font-size: 14px;
        color: #444444;
    }
    .btn-read-md{
        cursor: pointer;
        width:100px;
        height:32px;
        border-radius: 16px;
        background-color: #EFF2F5;
    }
    .btn-read-md span{
        font-size: 14px;
        color: #444444;
    }
}
.news-main{
    position: relative;
    border-radius: 16px;
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
}
.pin-box{
    position: absolute;
    top: -12px;
    right: 106px;
    width: 24px;
    height: 24px;
    border-radius: 16px;
    border: 2px solid #5284bd;
    background-color: #f9f9f9;
    z-index: 1;
}
.pin-box span{
    font-size: 11px;
    color: #555;
}
.recent-box{
    position: absolute;
    top: -12px;
    right: 106px;
    width: 40px;
    height: 24px;
    border-radius: 16px;
    border: 2px solid #5284bd;
    background-color: #f9f9f9;
    z-index: 1;
}
.recent-box span{
    font-size: 11px;
    color: #555;
}
.date-box{
    position: absolute;
    top: -12px;
    right: 10px;
    width: 90px;
    height: 24px;
    border-radius: 16px;
    border: 2px solid #c9c9c9;
    background-color: #f9f9f9;
    z-index: 1;
}
.date-box span{
    font-size: 11px;
    color: #555;
}
.font-noto{
    font-family: notoThRegular,notoEnRegular;
}
.font-thsarabun{
    font-family: thsarabun;
    line-height: 0;
}
.font-google-thsarabun{
    font-family: 'Sarabun', sans-serif;
    line-height: 1.4;
}
.date-custom{
    display: block;
    width: 100%;
    height: calc(1.9695rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.813rem;
    color: #665c70;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.delete-cover-img{
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 10px;
    width: 45px;
    height: 24px;
    border-radius: 16px;
    border: 2px solid #fb8e7e;
    background-color: #f9f9f9;
    z-index: 1;
}
.delete-cover-img span{
    font-size: 11px;
    color: #555;
}
.news-preview-title{
    font-size: 22px;
    color: #333;
    font-weight: bold;
    top:10px;
    position: relative;
}
.news-preview-content{
    margin-top:16px;
}